import { template as template_b2517471e33a46f8b2b6a95f0bd6facb } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_b2517471e33a46f8b2b6a95f0bd6facb(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
