import { template as template_390d2fd3462b42b3b7c4131e3f78deec } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_390d2fd3462b42b3b7c4131e3f78deec(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
