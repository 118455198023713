import { template as template_3f5b78f2b2fa4648a7ede8d6fdbda1ec } from "@ember/template-compiler";
const FKLabel = template_3f5b78f2b2fa4648a7ede8d6fdbda1ec(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
